import { useRef, useEffect } from "react";

import { FaRegCopyright as Copyright } from "react-icons/fa6";
import {
  SiSpotify as Spotify,
  SiApplemusic as Applemusic,
} from "react-icons/si";

import { ImSoundcloud2 as Soundcloud } from "react-icons/im";
import { FaBandcamp as Bandcamp } from "react-icons/fa";
import { RiInstagramFill as Instagram } from "react-icons/ri";

import kouyamusic from "./kouyamusic.mp4";
import "./App.css";

const icons = {
  spotify: (
    <a
      href="https://open.spotify.com/artist/6hkz5y5BVt1YkVlFJ8fk4A?si=H4BTfm6XQheprCM5h4XpCw"
      className="external-icon"
      target="_blank"
      rel="noreferrer"
      key="spotify"
    >
      <Spotify />
    </a>
  ),
  applemusic: (
    <a
      href="https://music.apple.com/us/artist/kouya/id1471742584"
      className="external-icon"
      target="_blank"
      rel="noreferrer"
      key="applemusic"
    >
      <Applemusic />
    </a>
  ),
  soundcloud: (
    <a
      href="https://soundcloud.com/kouyamusic"
      className="external-icon"
      target="_blank"
      rel="noreferrer"
      key="soundcloud"
    >
      <Soundcloud />
    </a>
  ),
  bandcamp: (
    <a
      href="https://kouyamusic.bandcamp.com"
      className="external-icon"
      target="_blank"
      rel="noreferrer"
      key="bandcamp"
    >
      <Bandcamp />
    </a>
  ),
  instagram: (
    <a
      href="https://instagram.com/kouya.j/"
      className="external-icon"
      target="_blank"
      rel="noreferrer"
      key="instagram"
    >
      <Instagram />
    </a>
  ),
};

function App() {
  const videoRef = useRef();

  const loadAndPlayVideo = () => {
    videoRef.current.load();
    videoRef.current.play();
  };

  useEffect(() => {
    // enable controls and play if ios is on low power mode
    if (videoRef.current) {
      videoRef.current.play().catch(() => {
        if (videoRef.current) videoRef.current.controls = true;
      });
    }
  }, []);

  const getCopyrightYear = () => {
    const YEAR_CREATED = 2023;
    const currentYear = new Date().getFullYear();

    return currentYear === YEAR_CREATED
      ? currentYear.toString()
      : `${YEAR_CREATED} - ${currentYear}`;
  };

  return (
    <div className="App">
      <video
        autoPlay
        muted
        loop
        playsInline
        preload="true"
        className="background"
        ref={videoRef}
        onEnded={() => loadAndPlayVideo()}
      >
        <source src={kouyamusic} type="video/mp4" />
      </video>

      <div className="header">
        <h1>KOUYA</h1>
      </div>
      <div className="links">{Object.values(icons)}</div>
      <div className="footer">
        <h1>
          <Copyright /> {getCopyrightYear()} Mario Juguilon
        </h1>
      </div>
    </div>
  );
}

export default App;
